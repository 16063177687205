import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Form, Input, Button, Layout, Spin } from "antd";
import { useHttpClient } from "../../shared/hooks/http-hook";
import MainNavigation from "../../shared/menu/MainNavigation";
import { AuthContext } from "../../shared/context/auth-context";
import { Helmet } from "react-helmet";
import axios from "axios";

const { Content, Sider } = Layout;

function EditCompany() {
  const auth = useContext(AuthContext);
  const id = useParams().id;
  const history = useHistory();
  const [loading, setLoading] = useState(null);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [companyName, setCompanyName] = useState();
  const [panNo, setPanNo] = useState();
  const [cinNo, setCinNo] = useState();
  const [gstNo, setGstNo] = useState();
  const [ifscCode, setIfscCode] = useState();
  const [branchName, setBranchName] = useState();
  const [accountNo, setAccountNo] = useState();
  const [ShowSuccessMessage, setShowSuccessMessage] = useState();

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const responseData = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}company/detail/${id}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setCompanyName(responseData.data.company.companyName);
        setPanNo(responseData.data.company.panNo);
        setCinNo(responseData.data.company.cinNo);
        setGstNo(responseData.data.company.gstNo);
        setIfscCode(responseData.data.company.ifscCode);
        setBranchName(responseData.data.company.branchName);
        setAccountNo(responseData.data.company.accountNo);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompany();
  }, [id, auth.token]);

  const SubmitHandler = async () => {
    try {
      setLoading(true);
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}update-company/${id}`,
        JSON.stringify({
          companyName: companyName,
          panNo: panNo,
          cinNo: cinNo,
          gstNo: gstNo,
          ifscCode: ifscCode,
          branchName: branchName,
          accountNo: accountNo,
          creator: auth.userId,
        }),
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      setShowSuccessMessage(true);
      history.push("/settings");
    } catch (err) {
      console.log(err);
    }
  };

  console.log(companyName);

  return (
    <Layout hasSider>
      {loading ? <Spin /> : !loading}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Company Details | BillSubmit</title>
        <link
          rel="canonical"
          href={`https://app.billsubmit.com/company/details/${id}`}
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div>
              <div>
                <Form layout="vertical" onFinish={SubmitHandler}>
                  <div>
                    <div>
                      <h2>Edit Company Details</h2>
                    </div>
                  </div>

                  <Form.Item label="Enter Your Full Name">
                    <Input
                      id="name"
                      placeholder="John Doe"
                      type="text"
                      size="large"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      rules={[
                        {
                          required: true,
                          message: "Please input your full name!",
                        },
                      ]}
                    />
                  </Form.Item>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "40px",
                    }}
                  >
                    <Form.Item
                      style={{ width: "100%" }}
                      label="Comapny GST Number"
                    >
                      <Input
                        id="gst"
                        placeholder="Enter your GST No"
                        type="text"
                        size="large"
                        value={gstNo}
                        onChange={(e) => setGstNo(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: "Please input company GST Number!",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: "100%" }}
                      label="Comapny PAN Number"
                    >
                      <Input
                        id="pan"
                        placeholder="Enter your PAN No"
                        type="text"
                        size="large"
                        value={panNo}
                        onChange={(e) => setPanNo(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: "Please input company PAN Number!",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: "100%" }}
                      label="Comapny CIN Number"
                    >
                      <Input
                        id="pan"
                        placeholder="Enter your CIN No"
                        type="text"
                        size="large"
                        value={cinNo}
                        onChange={(e) => setCinNo(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: "Please input company CIN Number!",
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "40px",
                    }}
                  >
                    <Form.Item
                      style={{ width: "100%" }}
                      label="Bank Account No"
                    >
                      <Input
                        id="account"
                        placeholder="Bank Account No"
                        type="text"
                        size="large"
                        value={accountNo}
                        onChange={(e) => setAccountNo(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: "Please enter bank account number!",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item style={{ width: "100%" }} label="Bank IFSC Code">
                      <Input
                        id="ifsc"
                        placeholder="Enter your IFSC Code"
                        type="text"
                        size="large"
                        value={ifscCode}
                        onChange={(e) => setIfscCode(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: "Please enter IFSC Code!",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: "100%" }}
                      label="Bank Branch Name"
                    >
                      <Input
                        id="branch"
                        placeholder="Connaught Place, Delhi"
                        type="text"
                        size="large"
                        value={branchName}
                        onChange={(e) => setBranchName(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: "Please enter bank branch name!",
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                  <Button size="large" type="primary" htmlType="submit">
                    Update Company Detail
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default EditCompany;
