import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import MainNavigation from "../../shared/menu/MainNavigation";
import { Layout, Space, Table, Result, Form, Input, Button, Spin } from "antd";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Helmet } from "react-helmet";
import axios from "axios";
const { Content, Sider } = Layout;

function CreateCompany() {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [panNo, setPanNo] = useState();
  const [cinNo, setCinNo] = useState();
  const [gstNo, setGstNo] = useState();
  const [ifscCode, setIfscCode] = useState();
  const [branchName, setBranchName] = useState();
  const [accountNo, setAccountNo] = useState();
  const [ShowSuccessMessage, setShowSuccessMessage] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();

  const SubmitHandler = async () => {
    try {
      setLoading(true);
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "create-company",
        {
          companyName: companyName,
          panNo: panNo,
          cinNo: cinNo,
          gstNo: gstNo,
          ifscCode: ifscCode,
          branchName: branchName,
          accountNo: accountNo,
          creator: auth.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
        }
      );
      setLoading(false);
      setShowSuccessMessage(true);
      history.push("/settings");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout hasSider>
      {loading ? <Spin /> : !loading}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create New Company | BillSubmit</title>
        <link
          rel="canonical"
          href="https://app.billsubmit.com/create-company"
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div>
              <div>
                <Form layout="vertical" onFinish={SubmitHandler}>
                  <div>
                    <div>
                      <h2>Add New Company</h2>
                    </div>
                  </div>

                  <Form.Item label="Enter Your Full Name">
                    <Input
                      id="name"
                      placeholder="John Doe"
                      type="text"
                      size="large"
                      onChange={(e) => setCompanyName(e.target.value)}
                      rules={[
                        {
                          required: true,
                          message: "Please input your full name!",
                        },
                      ]}
                    />
                  </Form.Item>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "40px",
                    }}
                  >
                    <Form.Item
                      style={{ width: "100%" }}
                      label="Company GST Number"
                    >
                      <Input
                        id="gst"
                        placeholder="Enter your GST No"
                        type="text"
                        size="large"
                        onChange={(e) => setGstNo(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: "Please input company GST Number!",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: "100%" }}
                      label="Company PAN Number"
                    >
                      <Input
                        id="pan"
                        placeholder="Enter your PAN No"
                        type="text"
                        size="large"
                        onChange={(e) => setPanNo(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: "Please input company PAN Number!",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: "100%" }}
                      label="Company CIN Number"
                    >
                      <Input
                        id="pan"
                        placeholder="Enter your CIN No"
                        type="text"
                        size="large"
                        onChange={(e) => setCinNo(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: "Please input company CIN Number!",
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "40px",
                    }}
                  >
                    <Form.Item
                      style={{ width: "100%" }}
                      label="Bank Account No"
                    >
                      <Input
                        id="account"
                        placeholder="Bank Account No"
                        type="text"
                        size="large"
                        onChange={(e) => setAccountNo(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: "Please enter bank account number!",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item style={{ width: "100%" }} label="Bank IFSC Code">
                      <Input
                        id="ifsc"
                        placeholder="Enter your IFSC Code"
                        type="text"
                        size="large"
                        onChange={(e) => setIfscCode(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: "Please enter IFSC Code!",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: "100%" }}
                      label="Bank Branch Name"
                    >
                      <Input
                        id="branch"
                        placeholder="Connaught Place, Delhi"
                        type="text"
                        size="large"
                        onChange={(e) => setBranchName(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: "Please enter bank branch name!",
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                  <Button size="large" type="primary" htmlType="submit">
                    Create Company
                  </Button>
                </Form>
                {ShowSuccessMessage && (
                  <Result
                    status="success"
                    title="Successfully saved the bills"
                    extra={[
                      <Link to="/">
                        <Button type="primary" key="success">
                          Go To Home
                        </Button>
                      </Link>,
                    ]}
                  />
                )}
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default CreateCompany;
