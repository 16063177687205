import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Form, Input, Button, Layout, Spin } from "antd";
import { useHttpClient } from "../../shared/hooks/http-hook";
import MainNavigation from "../../shared/menu/MainNavigation";
import { AuthContext } from "../../shared/context/auth-context";
import { Helmet } from "react-helmet";
import axios from "axios";

const { Content, Sider } = Layout;

function EditCategory() {
  const auth = useContext(AuthContext);
  const id = useParams().id;
  const history = useHistory();
  const [loading, setLoading] = useState(null);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [categoryName, setCategoryName] = useState();
  const [ShowSuccessMessage, setShowSuccessMessage] = useState();

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const responseData = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}category/detail/${id}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setCategoryName(responseData.category.categoryName);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompany();
  }, [id, auth.token]);

  const SubmitHandler = async () => {
    try {
      setLoading(true);
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}update-category/${id}`,
        {
          categoryName: categoryName,
          creator: auth.userId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      setShowSuccessMessage(true);
      history.push("/settings");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout hasSider>
      {loading ? <Spin /> : !loading}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Company Details | BillSubmit</title>
        <link
          rel="canonical"
          href={`https://app.billsubmit.com/company/details/${id}`}
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div>
              <div>
                <Form layout="vertical" onFinish={SubmitHandler}>
                  <div>
                    <div>
                      <h2>Edit Category</h2>
                    </div>
                  </div>

                  <Form.Item label="Enter Your Full Name">
                    <Input
                      id="name"
                      placeholder="John Doe"
                      type="text"
                      size="large"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                      rules={[
                        {
                          required: true,
                          message: "Please input category name!",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Button size="large" type="primary" htmlType="submit">
                    Update Category
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default EditCategory;
