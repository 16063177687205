import React from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import { Layout, Form, Input, Button, message, Upload, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useState } from "react";
import axios from "axios";

const { InputNumber } = Input;
const { Option } = Select;

const { Dragger } = Upload;
const { Content, Sider } = Layout;

function UploadBill() {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const props = {
    name: "file",
    multiple: true,
    action: "http://localhost:4000/api/bills",
    fileList,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      setFileList(info.fileList);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("files", file.originFileObj);
      });
      formData.append("name", values.name);
      formData.append("amount", values.amount);
      formData.append("category", values.category);
      const response = await axios.post(
        "http://localhost:4000/api/bills",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      message.success("Bills uploaded successfully");
      form.resetFields();
      setFileList([]);
    } catch (error) {
      console.error(error);
      message.error("Failed to upload bills");
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div className="">
              <div className="main-heading">
                <div>
                  <h2>Upload New Bills</h2>
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload your bills
                  </p>
                </Dragger>
              </div>
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                style={{ marginTop: "20px" }}
              >
                <Form.Item
                  label="Bill Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input bill name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Bill Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Please input bill amount",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  label="Bill Category"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Please select bill category",
                    },
                  ]}
                >
                  <Select placeholder="Select bill category" allowClear>
                    <Option value="category1">Category 1</Option>
                    <Option value="category2">Category 2</Option>
                    <Option value="category3">Category 3</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default UploadBill;
