import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import axios from "axios";
import {
  Layout,
  Form,
  Input,
  Button,
  message,
  Upload,
  Select,
  Radio,
} from "antd";
import MainNavigation from "../../shared/menu/MainNavigation";

const { Option } = Select;

const { Dragger } = Upload;
const { Content, Sider } = Layout;

const BulkBillUploader = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [Amount, setAmount] = useState();
  const [Category, setCategory] = useState();
  const [Type, setType] = useState();
  const [Vendor, setVendor] = useState();
  const [Tag, setTag] = useState();
  const [form] = Form.useForm();
  const [previewImage, setPreviewImage] = useState(null); // state for the preview image

  const auth = useContext(AuthContext);

  const handleFileUpload = () => {
    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append("file", file);
      formData.append("amount", Amount);
      formData.append("category", Category);
      formData.append("type", Type);
      formData.append("vendor", Vendor);
      formData.append("tag", Tag);
      formData.append("creator", auth.userId.toString());
    });

    axios
      .post("http://127.0.0.1:4000/api/bills", formData)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div className="">
              <div className="main-heading">
                <div>
                  <h2>Upload New Bills</h2>
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleFileUpload();
                  }}
                  onFinish={handleFileUpload}
                  layout="vertical"
                  form={form}
                  style={{
                    maxWidth: 600,
                  }}
                >
                  <Form.Item label="Add Vendor Name">
                    <Input
                      type="text"
                      placeholder="Add Vendor Name"
                      onChange={(e) => {
                        setVendor(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item label="Bill Amount">
                    <Input
                      type="text"
                      placeholder="Bill Amount"
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Select Category">
                    <Select
                      placeholder="Choose one Category"
                      onChange={(value) => setCategory(value)}
                    >
                      <Select.Option value="food">Food</Select.Option>
                      <Select.Option value="Internet">Internet</Select.Option>
                      <Select.Option value="Travel">Travel</Select.Option>
                      <Select.Option value="Electronics">
                        Electronics
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item label="Select Type">
                    <Select
                      placeholder="Choose one type"
                      onChange={(value) => setType(value)}
                    >
                      <Select.Option value="Qoute">Qoute</Select.Option>
                      <Select.Option value="Tax Invoice">
                        Tax Invoice
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Add Tag">
                    <Radio.Group
                      onChange={(e) => {
                        setTag(e.target.value);
                      }}
                    >
                      <Radio value="paid">Paid</Radio>
                      <Radio value="unpaid">Unpaid</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="Select Bill">
                    <Input
                      type="file"
                      multiple
                      onChange={(e) => setSelectedFiles([...e.target.files])}
                    />
                  </Form.Item>

                  <Button type="primary" htmlType="submit">
                    Save Bill
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default BulkBillUploader;
