import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import MainNavigation from "../../shared/menu/MainNavigation";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Helmet } from "react-helmet";
import axios from "axios";
import moment from "moment";

import { Layout, Space, Table, Spin, Button, DatePicker, Input } from "antd";
const { Content, Sider } = Layout;
const { Search } = Input;

function AllBills() {
  const [loading, setLoading] = useState(false);
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [bills, setBills] = useState([]);
  const [currentBill, setCurrentBill] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [filteredBills, setFilteredBills] = useState([]);

  useEffect(() => {
    const fetchBills = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/bills/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setLoading(false);
        setBills(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchBills();
  }, [auth.userId, auth.token]);

  useEffect(() => {
    let filtered = bills;
    if (searchText) {
      filtered = filtered.filter((bill) =>
        bill.vendor.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (dateRange) {
      filtered = filtered.filter((bill) => {
        const billDate = new Date(bill.uploadedAt);
        return billDate >= dateRange[0] && billDate <= dateRange[1];
      });
    }
    setFilteredBills(filtered);
  }, [bills, searchText, dateRange]);

  // Handle click of next button
  const handleNext = () => {
    if (currentBill < filteredBills.length - 1) {
      setCurrentBill(currentBill + 1);
    }
  };

  // Handle click of previous button
  const handlePrevious = () => {
    if (currentBill > 0) {
      setCurrentBill(currentBill - 1);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleDateRangeChange = (dateRange) => {
    setDateRange(dateRange);
  };

  const handleDateRangeClear = () => {
    setDateRange(null);
  };

  console.log("bills", bills);
  return (
    <Layout hasSider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>All Bills | BillSubmit</title>
        <link rel="canonical" href="https://app.billsubmit.com/" />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div>
                <h2>All Bills</h2>
              </div>

              <DatePicker.RangePicker
                style={{ marginRight: "10px" }}
                placeholder={["Start date", "End date"]}
                value={dateRange}
                onChange={handleDateRangeChange}
                onClear={handleDateRangeClear}
              />
              <Search
                placeholder="Search by vendor name"
                allowClear
                enterButton="Search"
                size="middle"
                onSearch={handleSearch}
                style={{ width: "30%" }}
              />
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <Link to="/">
                  <Button size="large" type="text">
                    Change to Table View
                  </Button>
                </Link>
                <Button size="large" onClick={handlePrevious} inverse>
                  See Previous Bill
                </Button>
                <Button size="large" type="primary" onClick={handleNext}>
                  See Next Bill
                </Button>
              </div>
            </div>

            {loading ? <Spin /> : !loading}

            {filteredBills.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    borderRight: "1px solid #f3f5f8",
                    padding: "20px 40px 0px 0px",
                  }}
                >
                  <div>
                    <p>Vendor Name</p>
                    <h2>{filteredBills[currentBill]?.vendor}</h2>
                  </div>
                  <div>
                    <p>Company Name</p>
                    <h2>{filteredBills[currentBill]?.company}</h2>
                  </div>
                  <div>
                    <p>Amount</p>
                    <h2>{filteredBills[currentBill]?.amount}</h2>
                  </div>
                  <div>
                    <p>Category</p>
                    <h2>{filteredBills[currentBill]?.category}</h2>
                  </div>
                  <div>
                    <p>Type</p>
                    <h2>{filteredBills[currentBill]?.type}</h2>
                  </div>
                  <div>
                    <p>Date</p>
                    <h2>
                      {moment(filteredBills[currentBill]?.uploadedAt).format(
                        "YYYY-MM-DD"
                      )}
                    </h2>
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    padding: "20px",
                    backgroundColor: "#F3F5F8",
                  }}
                >
                  {bills[currentBill]?.fileType === "pdf" ? (
                    <embed
                      src={`billsubmit.s3.ap-southeast-1.wasabisys.com/${filteredBills[currentBill]?.fileContent}`}
                      type="application/pdf"
                      style={{ width: "100%", height: "100vh" }}
                    />
                  ) : (
                    <img
                      src={`billsubmit.s3.ap-southeast-1.wasabisys.com/${filteredBills[currentBill]?.fileContent}`}
                      alt={filteredBills[currentBill]?.vendor}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default AllBills;
