import React, { useEffect, useState, useContext } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import axios from "axios";

import moment from "moment";

import {
  Layout,
  Space,
  Table,
  Tag,
  Input,
  DatePicker,
  Button,
  Select,
  Alert,
  Drawer,
} from "antd";
const { Content, Sider } = Layout;
const { Search } = Input;
const { Option } = Select;

function Dashboard() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [loadedBills, setLoadedBills] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [bills, setBills] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [currentBill, setCurrentBill] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [profile, setProfile] = useState();
  const [companies, setCompanies] = useState([]);

  console.log(auth.token);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setLoading(false);
        setProfile(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, [auth.token, auth.userId]);

  useEffect(() => {
    const fetchCompany = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}companies/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setLoading(false);
        const companyOptions = response.data.map((item) => (
          <Option key={item._id} value={item.companyName}></Option>
        ));
        setCompanies(companyOptions);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompany();
  }, [auth.token, auth.userId]);

  useEffect(() => {
    const fetchBills = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/bills/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        console.log(response.data, "datasss");
        const bills = response.data.map((row) => ({
          id: row._id,
          vendor: row.vendor,
          amount: row.amount,
          category: row.category,
          company: row.company,
          type: row.type,
          tag: row.tag,
          signedUrl: row.signedUrl,
          spaceLink: row.spaceLink,
          fileType: row.fileType,
          date: moment(row.date).format("YYYY-MM-DD"),
        }));

        // Sort the bills by date in descending order
        bills.sort((a, b) => new Date(b.date) - new Date(a.date));

        setBills(bills);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchBills();
  }, [auth.userId, auth.token]);

  useEffect(() => {
    let filtered = bills;
    if (searchText) {
      filtered = filtered.filter((bill) =>
        bill.vendor.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (dateRange) {
      filtered = filtered.filter((bill) => {
        const billDate = new Date(bill.date);
        return billDate >= dateRange[0] && billDate <= dateRange[1];
      });
    }
    if (selectedCompany) {
      filtered = filtered.filter((bill) => bill.company === selectedCompany);
    }

    // Sort the filtered bills by date in ascending order
    filtered.sort((a, b) => new Date(a.date) - new Date(b.date));

    setIsFilterApplied(
      searchText || dateRange || selectedCompany ? true : false
    );

    setFilteredBills(filtered);
  }, [bills, searchText, dateRange, selectedCompany]);

  const sortedData = isFilterApplied ? filteredBills : bills;

  console.log("bills", bills);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleDateRangeChange = (dateRange) => {
    setDateRange(dateRange);
  };

  const handleDateRangeClear = () => {
    setDateRange(null);
  };

  const handleCompanySelect = (value) => {
    setSelectedCompany(value);
  };

  const handleRefreshClick = () => {
    window.location.reload();
  };

  const deleteHandler = async (_id) => {
    setLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/delete-bill/${_id}`,
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      setLoading(false);

      setFilteredBills(filteredBills.filter((bill) => bill.id !== _id));
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      title: "Bill Date",
      dataIndex: "date",
      key: "date",
      render: (text) => <span>{moment(text).format("DD MMM YYYY")}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <span>Rs {text.toLocaleString()}</span>,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor",
      key: "vendor",
      render: (text, record) => (
        <Link
          to={`/bills/details/${record.id}`}
          style={{ textDecoration: "none" }}
        >
          {text}
        </Link>
      ),
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    // {
    //   title: "Bill Type",
    //   dataIndex: "type",
    //   key: "type",
    // },
    {
      title: "Tags",
      key: "tag",
      dataIndex: "tag",
      render: (text) => (
        <Tag color={text === "paid" ? "green" : "red"}>{text}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link
            to={`/bill/edit/detail/${record.id}`}
            style={{ textDecoration: "none" }}
          >
            Edit
          </Link>
          <p
            style={{ color: "red", margin: 0, cursor: "pointer" }}
            onClick={() => deleteHandler(record.id)}
          >
            Delete
          </p>
        </Space>
      ),
    },
  ];

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          {/* {profile?.subscriptionPlan === null && (
            <Alert
              message="Please start your free trial to use full potential of BillSubmit"
              type="success"
              showIcon
              closeText="Close Now"
              action={
                <Link to="/billing">
                  <Button type="primary">Start Free Trial</Button>
                </Link>
              }
            />
          )} */}
          <div>
            {companies.length === 0 && (
              <Alert
                message="Add a new company to create your bills"
                type="warning"
                showIcon
                closeText="Close Now"
                action={
                  <Link to="/create-company">
                    <Button type="text">Create Company</Button>
                  </Link>
                }
              />
            )}
          </div>
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div className="">
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div style={{ flex: 1, marginRight: "20px" }}>
                  <div className="main-heading">
                    <div>
                      <h2>All Bills</h2>
                      <p>Click on the bill row to see the preview</p>
                    </div>
                    <div>
                      <Link to="/upload">
                        <Button type="primary" size="large">
                          Upload New Bill
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      alignItems: "center",
                      gap: "40px",
                    }}
                  >
                    <DatePicker.RangePicker
                      placeholder={["Start date", "End date"]}
                      value={dateRange}
                      onChange={handleDateRangeChange}
                      onClear={handleDateRangeClear}
                      style={{ flex: 1 }}
                    />

                    <Select
                      placeholder="Filter by company name"
                      allowClear
                      value={selectedCompany}
                      onChange={handleCompanySelect}
                      style={{ flex: 1 }}
                    >
                      {bills &&
                        [...new Set(bills.map((bill) => bill.company))].map(
                          (company) => (
                            <Option key={company} value={company}>
                              {company}
                            </Option>
                          )
                        )}
                    </Select>

                    <Search
                      placeholder="Search by vendor name"
                      allowClear
                      enterButton="Search"
                      size="middle"
                      onSearch={handleSearch}
                      style={{ flex: 1 }}
                    />
                  </div>
                  <Table
                    dataSource={sortedData}
                    columns={columns}
                    loading={loading}
                    rowKey={(record) => record.id}
                    size="middle"
                    pagination={{ pageSize: 25 }}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          setCurrentBill(
                            sortedData.find((bill) => bill.id === record.id)
                          );
                        },
                      };
                    }}
                  />
                </div>
                {currentBill && (
                  <div
                    style={{
                      flex: 1,
                      padding: "20px",
                      backgroundColor: "#F3F5F8",
                    }}
                  >
                    <Button
                      style={{ marginBottom: "20px" }}
                      type="dashed"
                      onClick={handleRefreshClick}
                    >
                      Close View
                    </Button>
                    {currentBill.fileType === "pdf" ? (
                      <embed
                        src={currentBill.signedUrl}
                        type="application/pdf"
                        style={{ width: "100%", height: "100vh" }}
                      />
                    ) : (
                      <img
                        src={currentBill.signedUrl}
                        alt={currentBill.vendor}
                        style={{ width: "100%" }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
