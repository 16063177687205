import React from "react";
import { Link } from "react-router-dom";
import MainNavigation from "../../shared/menu/MainNavigation";
import Button from "../../shared/FormElements/Button";
import { Layout, Space, Table, Tag } from "antd";
const { Content, Sider } = Layout;

function AllUploaded() {
  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "vendor",
      key: "vendor",
      render: (text, record) => (
        <Link
          to={`/contacts/user/${record.id}`}
          style={{ textDecoration: "none" }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Bill Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/`} style={{ textDecoration: "none" }}>
            View Bill
          </Link>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      vendor: "Startbucks Coffee Pvt Lts",
      date: "11/2/22",
      amount: "$30.22",
      category: "Coffee bill",
      type: "Tax Invoice",
      tags: ["paid"],
    },
    {
      key: "2",
      vendor: "Startbucks Coffee Pvt Lts",
      date: "11/2/22",
      amount: "$30.22",
      category: "Coffee bill",
      type: "Tax Invoice",
      tags: ["paid"],
    },
    {
      key: "3",
      vendor: "Startbucks Coffee Pvt Lts",
      date: "11/2/22",
      amount: "$30.22",
      category: "Coffee bill",
      type: "Tax Invoice",
      tags: ["paid"],
    },
    {
      key: "4",
      vendor: "Startbucks Coffee Pvt Lts",
      date: "11/2/22",
      amount: "$30.22",
      category: "Coffee bill",
      type: "Tax Invoice",
      tags: ["paid"],
    },
  ];

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div className="">
              <div className="main-heading">
                <div>
                  <h2>All Uploaded</h2>
                </div>
                <div>
                  <Button to="/upload">Upload New Bill</Button>
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Table columns={columns} dataSource={data} />
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default AllUploaded;
