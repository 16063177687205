import React, { useEffect, useState, useContext } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import axios from "axios";
import moment from "moment";
import { Helmet } from "react-helmet";

import { Layout, Space, Table, Tag, Spin, Button, Alert, Select } from "antd";
const { Option } = Select;
const { Content, Sider } = Layout;

function PaidBill() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [loadedBills, setloadedBills] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/bills/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        console.log("response", response);
        setLoading(false);
        const paidBills = response.data.filter((bill) => bill.tag === "paid");
        setloadedBills(
          paidBills.map((row, index) => ({
            key: row.index,
            id: row._id,
            vendor: row.vendor,
            amount: row.amount,
            category: row.category,
            type: row.type,
            company: row.company,
            tag: row.tag,
            date: moment(row.uploadedAt).format("DD-MM-YYYY"),
          }))
        );
      } catch (err) {
        console.log(err);
      }
    };
    fetchContacts();
  }, [auth.token, auth.userId]);

  console.log("bills", loadedBills);

  useEffect(() => {
    const fetchCompany = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}companies/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setLoading(false);
        const companyOptions = response.data.map((item) => (
          <Option key={item._id} value={item.companyName}></Option>
        ));
        setCompanies(companyOptions);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompany();
  }, [auth.token, auth.userId]);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <span>Rs {text.toLocaleString()}</span>,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor",
      key: "vendor",
      render: (text, record) => (
        <Link
          to={`/bills/details/${record.id}`}
          style={{ textDecoration: "none" }}
        >
          {text}
        </Link>
      ),
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    // {
    //   title: "Bill Type",
    //   dataIndex: "type",
    //   key: "type",
    // },
    {
      title: "Tags",
      key: "tag",
      dataIndex: "tag",
      render: (text) => (
        <Tag color={text === "paid" ? "green" : "red"}>{text}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link
            to={`/bill/edit/detail/${record.id}`}
            style={{ textDecoration: "none" }}
          >
            Edits
          </Link>
          <p
            style={{ color: "red", margin: 0, cursor: "pointer" }}
            onClick={() => deleteHandler(record.id)}
          >
            Delete
          </p>
        </Space>
      ),
    },
  ];

  const deleteHandler = async (_id) => {
    setLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/delete-bill/${_id}`,
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      setLoading(false);

      setloadedBills(loadedBills.filter((bill) => bill.id !== _id));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Layout hasSider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Paid Bills | BillSubmit</title>
        <link rel="canonical" href="https://app.billsubmit.com/paid-bills" />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div>
            {companies.length === 0 && (
              <Alert
                message="Add a new company to create your bills"
                type="warning"
                showIcon
                closeText="Close Now"
                action={
                  <Link to="/create-company">
                    <Button type="primary">Create Company</Button>
                  </Link>
                }
              />
            )}
          </div>
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div className="">
              <div className="main-heading">
                <div>
                  <h2>Paid Bills</h2>
                </div>
                <div>
                  <Link to="/upload">
                    <Button type="primary" size="large">
                      Upload New Bill
                    </Button>
                  </Link>
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                {loading ? <Spin /> : !loading}

                {!loading && loadedBills && loadedBills.filter && (
                  <Table
                    columns={columns}
                    dataSource={loadedBills}
                    pagination={{ pageSize: 25 }}
                  />
                )}
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default PaidBill;
