import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import axios from "axios";

import { Layout, Space, Table, Spin } from "antd";

function CompanyList() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [companies, setCompanies] = useState([]);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    const fetchCompany = async () => {
      setLoading(true);
      try {
        const responseData = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}companies/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setLoading(false);
        setCompanies(responseData.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompany();
  }, [auth.token, auth.userId]);

  console.log("bills", companies);

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      render: (text, record) => (
        <Link
          to={`/company/detail/${record._id}`}
          style={{ textDecoration: "none" }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "GST No",
      dataIndex: "gstNo",
      key: "gstNo",
    },
    {
      title: "PAN No",
      dataIndex: "panNo",
      key: "panNo",
    },
    {
      title: "CIN No",
      dataIndex: "cinNo",
      key: "cinNo",
    },
    {
      title: "Account No",
      dataIndex: "accountNo",
      key: "accountNo",
    },
    {
      title: "IFSC Code",
      dataIndex: "ifscCode",
      key: "ifscCode",
    },
    {
      title: "Branch Name",
      dataIndex: "branchName",
      key: "branchName",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link
            to={`/company/detail/${record._id}`}
            style={{ textDecoration: "none" }}
          >
            Edit
          </Link>
          <p
            style={{ color: "red", margin: 0, cursor: "pointer" }}
            onClick={() => deleteHandler(record._id)}
          >
            Delete
          </p>
        </Space>
      ),
    },
  ];

  // delete category
  const deleteHandler = async (_id) => {
    setLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}delete-company/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      setLoading(false);
      setCompanies(companies.filter((contact) => contact._id !== _id));
    } catch (err) {
      console.error(err);
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div
      style={{
        background: "white",
      }}
    >
      <div className="">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "40px",
          }}
        ></div>
        <div style={{ marginTop: "20px" }}>
          {loading ? (
            <Spin />
          ) : (
            <Table dataSource={companies} columns={columns} />
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyList;
