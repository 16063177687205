import React, { useEffect, useState, useContext } from "react";
import MainNavigation from "../../shared/menu/MainNavigation";
import { Link } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import {
  Layout,
  Form,
  Input,
  Button,
  Radio,
  Upload,
  Select,
  Result,
  DatePicker,
  Spin,
  Alert,
} from "antd";
import Dropzone from "react-dropzone";
import axios from "axios";
import moment from "moment";
import { Helmet } from "react-helmet";

const { Option } = Select;

const { Dragger } = Upload;
const { Content, Sider } = Layout;
const { TextArea } = Input;

function UploadBills() {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [ShowSuccessMessage, setShowSuccessMessage] = useState();
  const { isLoading, sendRequest } = useHttpClient();
  const [companies, setCompanies] = useState([]);
  const [categories, setCategories] = useState();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [form] = Form.useForm();
  const auth = useContext(AuthContext);
  const [profile, setProfile] = useState();

  const [files, setFiles] = useState([]);

  const handleDrop = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) => ({
        file,
        vendor: "",
        amount: "",
        category: "",
        invoiceType: "",
        date: "",
        tag: "",
        type: file.type,
        company: "",
        comment: "",
        previewUrl: URL.createObjectURL(file),
      }))
    );
  };

  console.log("file", files);

  const handleInputChange = (index, name, value) => {
    setFiles((prevState) => {
      const updatedFiles = [...prevState];
      updatedFiles[index][name] = value;

      // Check if all fields are filled
      const isComplete = updatedFiles.every(
        (file) =>
          file.vendor &&
          file.amount &&
          file.category &&
          file.invoiceType &&
          file.tag &&
          file.date !== "" && // Check for null value here
          file.comment &&
          file.company
      );
      setIsFormComplete(isComplete);

      // Add selected date to the file object
      if (moment(value, "YYYY-MM-DD", true).isValid()) {
        const selectedDate = moment(value).format("YYYY-MM-DD");
        updatedFiles[index].date = selectedDate;
        console.log("seelected", selectedDate);
      }

      return updatedFiles;
    });
  };

  console.log(files);

  const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB in bytes

  useEffect(() => {
    const fetchCompany = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}companies/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setLoading(false);
        const companyOptions = response.data.map((item) => (
          <Option key={item._id} value={item.companyName}></Option>
        ));
        setCompanies(companyOptions);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompany();
  }, [auth.token, auth.userId]);

  useEffect(() => {
    const fetchCompany = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}categories/${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setLoading(false);
        setCategories(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompany();
  }, [auth.token, auth.userId]);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setLoading(false);
        setProfile(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, [auth.token, auth.userId]);

  const handleSubmit = async (e) => {
    setLoading(true);
    const formData = new FormData();
    let filesValid = true;
    files.forEach((file) => {
      if (file.file.size > MAX_FILE_SIZE) {
        filesValid = false;
        return;
      }

      const selectedDate = moment(file.date).format("YYYY-MM-DD");

      console.log("fileDate", file.date);
      console.log("selectedDate", selectedDate);

      formData.append("files", file.file);
      formData.append("vendor", file.vendor);
      formData.append("amounts", file.amount);
      formData.append("categories", file.category);
      formData.append("types", file.invoiceType);
      formData.append("tag", file.tag);
      formData.append("date", file.date);
      formData.append("company", file.company);
      formData.append("comment", file.comment);
      formData.append("creator", auth.userId.toString());
    });
    if (!filesValid) {
      setLoading(false);
      setError("One or more files are too large");
      return;
    }
    try {
      await axios.post(process.env.REACT_APP_BACKEND_URL + "bills", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setFiles([]);
      setLoading(false);
      setShowSuccessMessage(true);
    } catch (error) {
      console.log(error);
    }
  };

  const acceptedFileTypes = {
    "image/png": ".png",
    "image/jpeg": ".jpeg",
    "application/pdf": ".pdf",
  };

  return (
    <Layout hasSider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Upload New Bill | BillSubmit</title>
        <link rel="canonical" href="https://app.billsubmit.com/upload" />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            {loading ? <Spin /> : !loading}

            <div className="">
              <div style={{ marginTop: "20px" }}>
                <Form layout="vertical" onFinish={handleSubmit}>
                  <div
                    className="main-heading"
                    style={{ marginBottom: "40px" }}
                  >
                    <div>
                      <h2>Upload New Bills</h2>
                    </div>
                  </div>
                  {/* {profile?.subscriptionStatus === null && (
                    <Alert
                      message="Please start your free trial to start uploading your bills"
                      type="error"
                      showIcon
                      action={
                        <Link to="/billing">
                          <Button type="primary">Start Free Trial</Button>
                        </Link>
                      }
                    />
                  )} */}
                  <Dropzone
                    onDrop={handleDrop}
                    accept={acceptedFileTypes}
                    // disabled={profile?.subscriptionPlan === null}
                  >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          border: "2px dashed #eeeeee",
                          backgroundColor: "#F3F5F8",
                          color: "#bdbdbdb",
                        }}
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <p>Drop files here...</p>
                        ) : (
                          <p>
                            Drag and drop files here, or click to select files |
                            Max File Size : 3mb
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  {ShowSuccessMessage && (
                    <Result
                      status="success"
                      title="Successfully saved the bills"
                      extra={[
                        <Link to="/">
                          <Button type="primary" key="success">
                            Go To Home
                          </Button>
                        </Link>,
                      ]}
                    />
                  )}

                  {error && (
                    <Result
                      status="warning"
                      title="One or more files are too large, Try Again"
                    />
                  )}

                  {files.length > 0 && (
                    <div style={{ marginTop: "40px" }}>
                      {files.map((file, index) => (
                        <>
                          <h2 style={{ margin: "0" }}>Add Bill Detail</h2>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "space-between",
                              overflow: "hidden",
                            }}
                            key={file.file.preview}
                          >
                            <div
                              style={{
                                width: "50%",
                                padding: "20px 40px 0px 0px",
                              }}
                            >
                              <div>
                                <Form.Item label="Add Vendor Name">
                                  <Input
                                    size="large"
                                    placeholder="e.g. Google Ads"
                                    value={file.name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "vendor",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item label="Add Amount">
                                  <Input
                                    size="large"
                                    placeholder="5,000"
                                    value={file.amount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "amount",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "40px",
                                }}
                              >
                                <Form.Item
                                  label="Add Bill Category"
                                  style={{ width: "100%" }}
                                >
                                  <Select
                                    size="large"
                                    placeholder="Select a category"
                                    value={file.category}
                                    onChange={(value) =>
                                      handleInputChange(
                                        index,
                                        "category",
                                        value
                                      )
                                    }
                                  >
                                    {categories.map((x) => (
                                      <Select.Option value={x.categoryName}>
                                        {x.categoryName}
                                      </Select.Option>
                                    ))}
                                    <Select.Option value="food">
                                      Food
                                    </Select.Option>
                                    <Select.Option value="internet">
                                      Internet
                                    </Select.Option>
                                    <Select.Option value="electronics">
                                      Electronics
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  label="Date"
                                  style={{ width: "100%" }}
                                >
                                  <DatePicker
                                    size="large"
                                    selected={
                                      file.date
                                        ? moment(file.date).toDate()
                                        : null
                                    }
                                    onChange={(date) =>
                                      handleInputChange(index, "date", date)
                                    }
                                    placeholderText="Date"
                                    dateFormat="yyyy-MM-dd"
                                  />
                                </Form.Item>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Form.Item
                                  style={{ width: "100%" }}
                                  label="Add Bill Type"
                                >
                                  <Radio.Group
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "invoiceType",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <Radio value="Qoute">Qoute</Radio>
                                    <Radio value="Tax Invoice">
                                      Tax Invoice
                                    </Radio>
                                  </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                  style={{ width: "100%" }}
                                  label="Add Tag"
                                >
                                  <Radio.Group
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "tag",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <Radio value="paid">Paid</Radio>
                                    <Radio value="unpaid">Unpaid</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </div>

                              <Form.Item label="Select Company">
                                <Select
                                  placeholder="Select company"
                                  size="large"
                                  onChange={(value) =>
                                    handleInputChange(index, "company", value)
                                  }
                                >
                                  {companies}
                                </Select>
                                <div style={{ marginTop: "20px" }}>
                                  {companies.length === 0 && (
                                    <Alert
                                      message="Add a new company to create your bills"
                                      type="error"
                                      showIcon
                                      action={
                                        <Link to="/create-company">
                                          <Button type="primary">
                                            Create Company
                                          </Button>
                                        </Link>
                                      }
                                    />
                                  )}
                                </div>
                              </Form.Item>

                              <Form.Item label="Add Comment">
                                <TextArea
                                  placeholder="Enter a comment"
                                  onChange={(event) =>
                                    handleInputChange(
                                      index,
                                      "comment",
                                      event.target.value
                                    )
                                  }
                                  rows={3}
                                />
                              </Form.Item>
                            </div>
                            <div
                              style={{
                                width: "50%",
                                padding: "20px",
                                backgroundColor: "#F3F5F8",
                              }}
                            >
                              {file.type === "application/pdf" ? (
                                <object
                                  data={file.previewUrl}
                                  type="application/pdf"
                                  style={{ width: "100%", height: "500px" }}
                                >
                                  <embed
                                    src={file.previewUrl}
                                    type="application/pdf"
                                  />
                                </object>
                              ) : (
                                <img
                                  src={file.previewUrl}
                                  alt={file.file.name}
                                  style={{ width: "100%" }}
                                />
                              )}
                            </div>
                          </div>
                          <hr
                            style={{
                              border: "1px solid #F3F5F8",
                              margin: "40px 0px 40px 0px",
                            }}
                          ></hr>
                        </>
                      ))}
                    </div>
                  )}

                  <div style={{ marginTop: "40px" }}>
                    <Button
                      disabled={!isFormComplete}
                      size="large"
                      type="primary"
                      htmlType="submit"
                    >
                      Upload Bills
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default UploadBills;
