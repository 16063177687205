import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import MainNavigation from "../../shared/menu/MainNavigation";
import { Layout, Space, Table, Result, Form, Input, Button, Spin } from "antd";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Helmet } from "react-helmet";
import axios from "axios";
const { Content, Sider } = Layout;

function CreateCategory() {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState();
  const [ShowSuccessMessage, setShowSuccessMessage] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();

  const SubmitHandler = async () => {
    try {
      setLoading(true);
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "create-category",
        {
          categoryName: categoryName,
          creator: auth.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
        }
      );
      setLoading(false);
      setShowSuccessMessage(true);
      history.push("/settings");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Layout hasSider>
      {loading ? <Spin /> : !loading}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create New Category | BillSubmit</title>
        <link
          rel="canonical"
          href="https://app.billsubmit.com/create-category"
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div>
              <div>
                <Form layout="vertical" onFinish={SubmitHandler}>
                  <div>
                    <div>
                      <h2>Add New Category</h2>
                    </div>
                  </div>

                  <Form.Item label="Enter Category Name">
                    <Input
                      id="name"
                      placeholder="e.g Server Bills"
                      type="text"
                      size="large"
                      onChange={(e) => setCategoryName(e.target.value)}
                      rules={[
                        {
                          required: true,
                          message: "Please input your full name!",
                        },
                      ]}
                    />
                  </Form.Item>

                  <Button size="large" type="primary" htmlType="submit">
                    Create Category
                  </Button>
                </Form>
                {ShowSuccessMessage && (
                  <Result
                    status="success"
                    title="Successfully saved the bills"
                    extra={[
                      <Link to="/">
                        <Button type="primary" key="success">
                          Go To Home
                        </Button>
                      </Link>,
                    ]}
                  />
                )}
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default CreateCategory;
