import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from "./components/dashboard/pages/Dashboard";
// import Campaign from "./components/campaigns/pages/Campaign";
import Auth from "./components/auth/Auth";
import { AuthContext } from "./components/shared/context/auth-context";
import { useAuth } from "./components/shared/hooks/auth-hook";
import Upload from "./components/upload/pages/Upload";
import AllUploaded from "./components/AllUploaded/pages/AllUploaded";
import PendingPayment from "./components/PendingPayment/pages/PendingPayment";
import PaidBill from "./components/PaidBill/pages/PaidBill";
import BulkBillUploader from "./components/upload/pages/BulkBillUploader";
import BillDetails from "./components/BillDetails/pages/BillDetails";
import UploadBills from "./components/upload/pages/UploadBills";
import AllBills from "./components/AllBills/pages/AllBills";
import Settings from "./components/Settings/Pages/Settings";
import CreateCompany from "./components/Settings/Pages/CreateCompany";
import EditCompany from "./components/Settings/Pages/EditCompany";
import EditBillDetails from "./components/BillDetails/pages/EditBillDetails";
import VerifyEmail from "./components/auth/VerifyEmail";
import CreateCategory from "./components/Settings/Pages/CreateCategory";
import EditCategory from "./components/Settings/Pages/EditCategory";
import Verified from "./components/auth/Verified";
import ManageBilling from "./components/Billing/pages/ManageBilling";
import ResetPassword from "./components/auth/ResetPassword";
import NewPassword from "./components/auth/NewPassword";

function App() {
  const { token, login, logout, userId, isLoading } = useAuth();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  let routes;

  useEffect(() => {
    if (!isLoading) {
      setIsCheckingAuth(false);
    }
  }, [isLoading]);

  if (isLoading || isCheckingAuth) {
    routes = <div>Loading...</div>;
  } else if (token) {
    routes = (
      <>
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route exact path="/upload">
            <UploadBills />
          </Route>
          <Route exact path="/bulk-upload">
            <BulkBillUploader />
          </Route>
          <Route exact path="/paid-bills">
            <PaidBill />
          </Route>
          <Route exact path="/pending-payment">
            <PendingPayment />
          </Route>
          <Route exact path="/bills/details/:id">
            <BillDetails />
          </Route>
          {/* <Route exact path="/all-uploaded">
            <AllUploaded />
          </Route> */}
          <Route exact path="/all-bills">
            <AllBills />
          </Route>
          <Route exact path="/create-company">
            <CreateCompany />
          </Route>
          <Route exact path="/create-category">
            <CreateCategory />
          </Route>
          <Route exact path="/company/detail/:id">
            <EditCompany />
          </Route>
          <Route exact path="/category/detail/:id">
            <EditCategory />
          </Route>
          <Route exact path="/bill/edit/detail/:id">
            <EditBillDetails />
          </Route>
          <Route exact path="/settings">
            <Settings />
          </Route>
          {/* <Route exact path="/billing">
            <ManageBilling />
          </Route> */}
          <Route exact path="/billing?success=true&session_id=:sessionId">
            <ManageBilling />
          </Route>
          <Route exact path="/billing?canceled=true">
            <ManageBilling />
          </Route>
          <Redirect to="/" />
        </Switch>
      </>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/auth">
          <Auth />
        </Route>
        <Route exact path="/verify-email">
          <VerifyEmail />
        </Route>
        <Route exact path="/verified">
          <Verified />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/new-password/:token">
          <NewPassword />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
      <Router>{routes}</Router>
    </AuthContext.Provider>
  );
}

export default App;
