import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import MainNavigation from "../../shared/menu/MainNavigation";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Document, Page } from "react-pdf";
import { AuthContext } from "../../shared/context/auth-context";
import { Helmet } from "react-helmet";
import moment from "moment";

import { Layout, Spin, Button } from "antd";
const { Content, Sider } = Layout;

function BillDetails() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [loadedBills, setloadedBills] = useState();
  const [billLink, setBillLink] = useState();
  const location = useLocation();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(location.pathname);

  const userId = useParams().id;

  useEffect(() => {
    setCurrentPage(location.pathname);

    if (location.pathname !== location.pathname) {
      history.push(location.pathname);
    }
  }, [location, history]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/bills/details/${userId}?userId=${auth.userId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );

        console.log("response", response.data.signedUrl);
        setLoading(false);
        setloadedBills(response.data.bill);
        setBillLink(response.data.signedUrl);
      } catch (err) {
        console.log(err);
      }
    };

    fetchContacts();
  }, [userId, auth.token, auth.userId]);

  console.log(loadedBills);

  const renderFile = () => {
    if (loadedBills?.fileType === "pdf") {
      return (
        <embed
          src={billLink}
          type="application/pdf"
          style={{ width: "100%", height: "100vh" }}
        />
      );
    } else {
      return (
        <img
          src={billLink}
          alt={loadedBills?.vendor}
          style={{ width: "100%" }}
        />
      );
    }
  };

  const downloadBill = () => {
    const fileUrl = billLink;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = loadedBills?.filename;
    link.target = "_blank";
    link.click();
  };

  const printBill = () => {
    const printContent = document.getElementById("bill-details").innerHTML;
    const originalContent = document.body.innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
  };

  return (
    <Layout hasSider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bill Details | BillSubmit</title>
        <link
          rel="canonical"
          href={`https://app.billsubmit.com/bills/details/${userId}`}
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      {loading ? <Spin /> : !loading}

      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div className="">
              <div>
                <h2>Bill Details</h2>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    borderRight: "1px solid #f3f5f8",
                    padding: "20px 40px 0px 0px",
                  }}
                >
                  <div>
                    <p>Vendor Name</p>
                    <h2>{loadedBills?.vendor}</h2>
                  </div>
                  <div>
                    <p>Company</p>
                    <h2>{loadedBills?.company}</h2>
                  </div>
                  <div>
                    <p>Amount</p>
                    <h2>Rs {loadedBills?.amount?.toLocaleString("en-IN")}</h2>
                  </div>

                  <div>
                    <p>Category</p>
                    <h2>{loadedBills?.category}</h2>
                  </div>
                  <div>
                    <p>Type</p>
                    <h2>{loadedBills?.type}</h2>
                  </div>
                  <div>
                    <p>Comment</p>
                    <h2>{loadedBills?.comment}</h2>
                  </div>
                  <div>
                    <p>Date</p>
                    <h2>
                      {moment(loadedBills?.uploadedAt).format("DD-MM-YYYY")}
                    </h2>
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    padding: "20px",
                    backgroundColor: "#F3F5F8",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "8px",
                        border: "1px solid #dddddd",
                        cursor: "pointer",
                      }}
                      onClick={downloadBill}
                    >
                      Download Bill
                    </div>
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "8px",
                        border: "1px solid #dddddd",
                        cursor: "pointer",
                      }}
                      onClick={printBill}
                    >
                      Print Bill
                    </div>
                  </div>

                  <div id="bill-details">{renderFile()}</div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default BillDetails;
