import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Layout,
  Spin,
  Select,
  DatePicker,
  Radio,
} from "antd";
import { useHttpClient } from "../../shared/hooks/http-hook";
import MainNavigation from "../../shared/menu/MainNavigation";
import { AuthContext } from "../../shared/context/auth-context";
import { Helmet } from "react-helmet";
import moment from "moment";

const { Content, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;

function EditBillDetails() {
  const auth = useContext(AuthContext);
  const userId = useParams().id;
  const history = useHistory();
  const [loading, setLoading] = useState(null);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [vendor, setVendor] = useState();
  const [amount, setAmount] = useState();
  const [category, setCategory] = useState();
  const [company, setCompany] = useState();
  const [tag, setTag] = useState();
  const [type, setType] = useState();
  const [date, setDate] = useState();
  const [loadedBill, setLoadedBill] = useState();
  const [companies, setCompanies] = useState([]);
  const [categories, setCategories] = useState();
  const [comment, setComment] = useState();
  const [ShowSuccessMessage, setShowSuccessMessage] = useState();
  const [billLink, setBillLink] = useState();

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        setLoading(true);

        const responseData = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/bills/details/${userId}?userId=${auth.userId}`,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        setLoading(false);

        setVendor(responseData.data.bill.vendor);
        setAmount(responseData.data.bill.amount);
        setCategory(responseData.data.bill.category);
        setCompany(responseData.data.bill.company);
        setTag(responseData.data.bill.tag);
        setType(responseData.data.bill.type);
        setComment(responseData.data.bill.comment);
        setDate(
          responseData.data.bill.date
            ? moment(responseData.data.bill.date)
            : null
        );
        setBillLink(responseData.data.signedUrl);
        setLoadedBill(responseData.data.bill);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompany();
  }, [auth.token, userId, auth.userId]);

  console.log(vendor, amount, category, company, tag, type, date);

  const SubmitHandler = async () => {
    try {
      setLoading(true);
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + `update-bill/${userId}`,
        {
          vendor: vendor,
          amounts: amount,
          categories: category,
          types: type,
          tag: tag,
          date: date,
          company: company,
          comment: comment,
          creator: auth.userId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      setShowSuccessMessage(true);
      history.push("/");
    } catch (err) {
      console.log(err);
    }
  };

  const renderFile = () => {
    if (loadedBill?.fileType === "pdf") {
      return (
        <embed
          src={billLink}
          type="application/pdf"
          style={{ width: "100%", height: "100vh" }}
        />
      );
    } else {
      return (
        <img
          src={billLink}
          alt={loadedBill?.vendor}
          style={{ width: "100%" }}
        />
      );
    }
  };

  useEffect(() => {
    const fetchCompany = async () => {
      setLoading(true);
      try {
        const responseData = await axios.get(
          process.env.REACT_APP_BACKEND_URL + `companies/${auth.userId}`,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        setLoading(false);
        const companyOptions = responseData.data.map((item) => (
          <Option key={item._id} value={item.companyName}></Option>
        ));
        setCompanies(companyOptions);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompany();
  }, [auth.token, auth.userId]);

  useEffect(() => {
    const fetchCompany = async () => {
      setLoading(true);
      try {
        const responseData = await axios.get(
          process.env.REACT_APP_BACKEND_URL + `categories/${auth.userId}`,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        setLoading(false);
        setCategories(responseData.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompany();
  }, [auth.token, auth.userId]);

  console.log(categories);

  const handleDateChange = (date) => {
    if (date) {
      setDate(date);
    } else {
      setDate(null);
    }
  };

  return (
    <Layout hasSider>
      {loading ? <Spin /> : !loading}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Company Details | BillSubmit</title>
        <link
          rel="canonical"
          href={`https://app.billsubmit.com/company/details/${userId}`}
        />
      </Helmet>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <MainNavigation />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              background: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "50%",
                  borderRight: "1px solid #f3f5f8",
                  padding: "20px 40px 0px 0px",
                }}
              >
                <Form layout="vertical" onFinish={SubmitHandler}>
                  <div>
                    <div>
                      <h2>Edit Bill Details</h2>
                    </div>
                  </div>

                  <Form.Item label="Vendor Name">
                    <Input
                      id="vendor"
                      placeholder="MyDesk Coworking Pvt Ltd"
                      type="text"
                      size="large"
                      value={vendor}
                      onChange={(e) => setVendor(e.target.value)}
                      rules={[
                        {
                          required: true,
                          message: "Please input your full name!",
                        },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item label="Amount">
                    <Input
                      id="amount"
                      placeholder="5,000"
                      size="large"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item label="Select Category">
                    <Select
                      size="large"
                      placeholder="Select a category"
                      value={category}
                      onChange={(value) => setCategory(value)}
                    >
                      {categories?.map((x) => (
                        <Option value={x.categoryName}>{x.categoryName}</Option>
                      ))}
                      <Option value="food">Food</Option>
                      <Option value="internet">Internet</Option>
                      <Option value="electronics">Electronics</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item label="Date">
                    <DatePicker
                      size="large"
                      placeholder="Select a date"
                      value={date}
                      onChange={handleDateChange}
                      format="YYYY-MM-DD"
                      allowClear
                    />
                  </Form.Item>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item style={{ width: "100%" }} label="Add Bill Type">
                      <Radio.Group
                        onChange={(e) => setType(e.target.value)}
                        value={type}
                      >
                        <Radio value="Qoute">Qoute</Radio>
                        <Radio value="Tax Invoice">Tax Invoice</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item style={{ width: "100%" }} label="Add Tag">
                      <Radio.Group
                        onChange={(e) => setTag(e.target.value)}
                        value={tag}
                      >
                        <Radio value="paid">Paid</Radio>
                        <Radio value="unpaid">Unpaid</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  <Form.Item label="Select Company">
                    <Select
                      placeholder="Select company"
                      size="large"
                      onChange={(value) => setCompany(value)}
                      value={company}
                    >
                      {companies}
                    </Select>
                  </Form.Item>

                  <Form.Item label="Add Comment">
                    <TextArea
                      placeholder="Enter a comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      rows={3}
                    />
                  </Form.Item>

                  <Button size="large" type="primary" htmlType="submit">
                    Save Bill Detail
                  </Button>
                </Form>
              </div>
              <div
                style={{
                  width: "50%",
                  padding: "20px",
                  backgroundColor: "#F3F5F8",
                  height: "100%",
                }}
              >
                {renderFile()}
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default EditBillDetails;
