import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import MainNavigation from "../../shared/menu/MainNavigation";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Helmet } from "react-helmet";
import axios from "axios";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import monthly from "../../../img/Monthly.png";
import annual from "../../../img/Annual.png";
import {
  Layout,
  Space,
  Table,
  Spin,
  Button,
  DatePicker,
  Tag,
  Result,
} from "antd";
const { Content, Sider } = Layout;

const stripePromise = loadStripe(
  "pk_test_51MwfnvSIOxkgcyucsc98XaHk9MaVlL7XPbcVLmkUQqHMsneu7bchjPXIC8IlW1edqfRifBpPgneB0qV2sH22zg07009E1W3TPK"
);

const ProductDisplay = () => {
  // const auth = useContext(AuthContext);
  // const [loading, setLoading] = useState(false);
  // const { isLoading, error, sendRequest } = useHttpClient();
  // const [profile, setProfile] = useState();
  // const [trialDaysLeft, setTrialDaysLeft] = useState(null);

  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
  //         {
  //           headers: { Authorization: `Bearer ${auth.token}` },
  //         }
  //       );
  //       setLoading(false);
  //       setProfile(response.data);
  //       // Get subscription information
  //       const subscriptionResponse = await axios.get(
  //         `${process.env.REACT_APP_BACKEND_URL}subscription/${response.data.stripeSubscriptionId}`,
  //         {
  //           headers: { Authorization: `Bearer ${auth.token}` },
  //         }
  //       );
  //       const { status, current_period_end } = subscriptionResponse.data;
  //       console.log(subscriptionResponse);

  //       if (status === "trialing") {
  //         // Calculate the remaining trial days
  //         const today = new Date();
  //         const trialEnds = new Date(current_period_end * 1000);
  //         const daysLeft = Math.ceil(
  //           (trialEnds - today) / (1000 * 60 * 60 * 24)
  //         );
  //         setTrialDaysLeft(daysLeft);
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchProfile();
  // }, [auth.token, auth.userId]);

  const userId = JSON.parse(localStorage.getItem("userData")).userId;
  console.log(userId);

  return (
    <section>
      <div>
        <h2>Upgrade Your Plan</h2>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          border: "1px solid #f5f5f5",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: 10,
          }}
        >
          <div>
            <img src={annual} alt="monthly" />
          </div>
          <div>
            <h3 style={{ margin: 0, fontSize: "1.2rem" }}>Annual plan</h3>
            <p style={{ margin: 0, marginTop: "8px", fontSize: "1rem" }}>
              Rs 10,000/Yearly | First 30 days are free and you can cancel
              anytime in these 30 days
            </p>
            <ul>
              <li>
                <p style={{ margin: 0, marginTop: "16px", fontSize: "1rem" }}>
                  You can upload UNLIMITED BILLS
                </p>
              </li>
              <li>
                <p style={{ margin: 0, marginTop: "12px", fontSize: "1rem" }}>
                  You can create UNLIMITED COMPANIES
                </p>
              </li>
              <li>
                <p style={{ margin: 0, marginTop: "12px", fontSize: "1rem" }}>
                  You can create UNLIMITED CATEGORIES
                </p>
              </li>
              <li>
                <p style={{ margin: 0, marginTop: "12px", fontSize: "1rem" }}>
                  Access to our upcoming Mobile App
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <form
            action={`http://127.0.0.1:8080/api/create-checkout-session?userId=${userId}`}
            method="POST"
          >
            {/* Add a hidden field with the lookup_key of your Price */}
            <input
              type="hidden"
              name="lookup_key"
              value="price_1My96USIOxkgcyucJzTDi1Ke"
            />
            <input type="hidden" name="userId" value={userId} />
            <Button
              id="checkout-and-portal-button"
              type="primary"
              htmlType="submit"
              size="large"
            >
              Start Free Trial
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
};

const SuccessDisplay = ({ sessionId }) => {
  const userId = JSON.parse(localStorage.getItem("userData")).userId;
  console.log(userId);

  useEffect(() => {
    console.log(sessionId);
    const updateUser = async () => {
      try {
        const response = await fetch(
          `http://127.0.0.1:8080/api/update-user?sessionId=${sessionId}&userId=${userId}`
        );
        console.log(response);
        if (response.ok) {
          console.log("Data Saved");
        } else {
          console.log("Failed to update user");
        }
      } catch (error) {
        console.log(error);
      }
    };

    updateUser();
  }, [sessionId]);

  return (
    <section>
      {/* <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <form
        action="http://127.0.0.1:8080/api/create-portal-session"
        method="POST"
      >
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </button>
      </form> */}

      <Result
        status="success"
        title="Successfully started the free trial for next 30 days"
        extra={[
          <Link to="/">
            <Button type="primary" key="success">
              Go To Home
            </Button>
          </Link>,
        ]}
      />
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function ManageBilling() {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  const [subscription, setSubscription] = useState(null);
  const [profile, setProfile] = useState();
  const auth = useContext(AuthContext);

  const userId = JSON.parse(localStorage.getItem("userData")).userId;

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setProfile(response.data);

        // Get subscription information
        const subscriptionResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}subscription/${response.data.stripeSubscriptionId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setSubscription(subscriptionResponse.data);
        console.log("subscription.data", subscriptionResponse.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, [auth.token, auth.userId]);

  const handleCancelSubscription = async () => {
    try {
      const response = await axios.post(
        "http://127.0.0.1:8080/api/cancel-subscription",
        {
          subscriptionId: subscription?.id,
          userId: userId,
        }
      );
      console.log(response.data); // success message from server
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  if (!success && message === "") {
    return (
      <Layout hasSider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Manage Billing | BillSubmit</title>
          <link rel="canonical" href="https://app.billsubmit.com/" />
        </Helmet>

        <Sider
          style={{
            overflow: "hidden",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <MainNavigation />
        </Sider>

        <Layout
          className="site-layout"
          style={{
            marginLeft: 200,
          }}
        >
          <Content
            style={{
              margin: "24px 16px 0",
              overflow: "initial",
            }}
          >
            <div
              style={{
                padding: 24,
                background: "white",
              }}
            >
              {profile && profile.subscriptionPlan === "Yearly Plan" ? (
                profile.subscriptionStatus === "canceled" ? (
                  <div>
                    <h2>Your subscription has been canceled</h2>
                    <p style={{ width: "60%" }}>
                      Your subscription has been canceled, but you can still
                      access your account until the end of your billing cycle.
                      If you want to resubscribe, you can do so once your trial
                      period is over.
                    </p>

                    <div style={{ marginTop: "60px" }}>
                      <div>
                        <h3>Activated Plan</h3>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          border: "1px solid #f5f5f5",
                          padding: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: 10,
                          }}
                        >
                          <div>
                            <img src={annual} alt="monthly" />
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                              }}
                            >
                              <h3 style={{ margin: 0, fontSize: "1.2rem" }}>
                                {subscription
                                  ? "Yearly Plan"
                                  : "Not Subscribed"}
                              </h3>
                              <p style={{ margin: 0, marginTop: "4px" }}>
                                {subscription ? (
                                  <Tag color="red">{subscription.status}</Tag>
                                ) : (
                                  "Not Subscribed"
                                )}
                              </p>
                            </div>
                            <p
                              style={{
                                margin: 0,
                                marginTop: "12px",
                                fontSize: "1rem",
                              }}
                            >
                              Subscribed On:{" "}
                              {subscription
                                ? new Date(
                                    subscription.current_period_start * 1000
                                  ).toLocaleDateString()
                                : "N/A"}
                            </p>
                            <p
                              style={{
                                margin: 0,
                                marginTop: "12px",
                                fontSize: "1rem",
                              }}
                            >
                              Plan Expiring On:
                              {subscription
                                ? new Date(
                                    subscription.current_period_end * 1000
                                  ).toLocaleDateString()
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <h2>Subscribed Plan Detail</h2>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        border: "1px solid #f5f5f5",
                        padding: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 10,
                        }}
                      >
                        <div>
                          <img src={annual} alt="monthly" />
                        </div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <h3 style={{ margin: 0, fontSize: "1.2rem" }}>
                              {subscription ? "Yearly Plan" : "Not Subscribed"}
                            </h3>
                            <p style={{ margin: 0, marginTop: "4px" }}>
                              {subscription ? (
                                <Tag color="green">{subscription.status}</Tag>
                              ) : (
                                "Not Subscribed"
                              )}
                            </p>
                          </div>
                          <p
                            style={{
                              margin: 0,
                              marginTop: "12px",
                              fontSize: "1rem",
                            }}
                          >
                            Subscribed On:{" "}
                            {subscription
                              ? new Date(
                                  subscription.current_period_start * 1000
                                ).toLocaleDateString()
                              : "N/A"}
                          </p>
                          <p
                            style={{
                              margin: 0,
                              marginTop: "12px",
                              fontSize: "1rem",
                            }}
                          >
                            Plan Expiring On:
                            {subscription
                              ? new Date(
                                  subscription.current_period_end * 1000
                                ).toLocaleDateString()
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                      <div>
                        <Button
                          id="checkout-and-portal-button"
                          type="primary"
                          style={{ backgroundColor: "#FF0000" }}
                          size="large"
                          onClick={handleCancelSubscription}
                        >
                          Cancel Subscription
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <ProductDisplay />
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  } else if (success && sessionId !== "") {
    return (
      <Layout hasSider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Manage Billing | BillSubmit</title>
          <link rel="canonical" href="https://app.billsubmit.com/" />
        </Helmet>

        <Sider
          style={{
            overflow: "hidden",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <MainNavigation />
        </Sider>

        <Layout
          className="site-layout"
          style={{
            marginLeft: 200,
          }}
        >
          <Content
            style={{
              margin: "24px 16px 0",
              overflow: "initial",
            }}
          >
            <div
              style={{
                padding: 24,
                background: "white",
              }}
            >
              <SuccessDisplay sessionId={sessionId} />
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  } else {
    return (
      <Layout hasSider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Manage Billing | BillSubmit</title>
          <link rel="canonical" href="https://app.billsubmit.com/" />
        </Helmet>

        <Sider
          style={{
            overflow: "hidden",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <MainNavigation />
        </Sider>

        <Layout
          className="site-layout"
          style={{
            marginLeft: 200,
          }}
        >
          <Content
            style={{
              margin: "24px 16px 0",
              overflow: "initial",
            }}
          >
            <div
              style={{
                padding: 24,
                background: "white",
              }}
            >
              <Message message={message} />
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}
