import React, { useState, useContext } from "react";
import "./Auth.css";
import { Link, useHistory } from "react-router-dom";
import { Input, Form, Button, Spin } from "antd";
import { useForm } from "../shared/hooks/form-hook";
import ErrorModal from "../shared/uiElement/ErrorModal";
import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";
import thumb from "../../img/logo-bill-color.png";
import loginPic from "../../img/Login.png";

const Auth = () => {
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [name, setName] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const authSubmitHandler = async (event) => {
    if (isLoginMode) {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "auth/login",
          "POST",
          JSON.stringify({
            email: email,
            password: password,
          }),
          {
            "Content-Type": "application/json",
          }
        );

        console.log(responseData);
        if (responseData.isVerified === true) {
          auth.login(responseData.userId, responseData.token);
        } else if (responseData.isVerified === false) {
          window.location.href = "/verify-email";
        }

        // auth.login(responseData.userId, responseData.token);
        console.log(responseData.name);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "auth/signup",
          "POST",
          JSON.stringify({
            name: name,
            email: email,
            password: password,
          }),
          {
            "Content-Type": "application/json",
          }
        );
        console.log(responseData);
        if (responseData.isVerified === false) {
          window.location.href = "/verify-email";
        } else if (responseData.isVerified === true) {
          auth.login(responseData.userId, responseData.token);
          console.log("name", responseData.name);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    }
    setIsLoginMode((prevMode) => !prevMode);
  };
  return (
    <div className="auth-comp">
      <div className="auth-header">
        <div>
          <img
            style={{ paddingLeft: "4%" }}
            className="auth-logo"
            src={thumb}
            alt="logo"
          />
        </div>
        {isLoading && <Spin />}
        <Form
          style={{ width: "80%", padding: "0% 5% 0% 5%" }}
          layout="vertical"
          onFinish={authSubmitHandler}
        >
          {!isLoginMode && (
            <>
              <Form.Item label="Enter Your Full Name">
                <Input
                  id="name"
                  placeholder="John Doe"
                  type="text"
                  size="large"
                  onChange={(e) => setName(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "Please input your full name!",
                    },
                  ]}
                />
              </Form.Item>

              {/* <Form.Item label="Enter Your Company Name">
                <Input
                  id="name"
                  placeholder="Enter your company name"
                  type="text"
                  size="large"
                  onChange={(e) => setCompany(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: "Please input your company name!",
                    },
                  ]}
                />
              </Form.Item> */}
            </>
          )}
          <Form.Item label="Enter Email">
            <Input
              id="email"
              placeholder="johndoe@gmail.com"
              type="email"
              size="large"
              onChange={(e) => setEmail(e.target.value)}
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            />
          </Form.Item>

          <Form.Item label="Enter Your Password">
            <Input
              id="password"
              type="password"
              size="large"
              placeholder="Enter Your Password"
              onChange={(e) => setPassword(e.target.value)}
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            />
          </Form.Item>
          <Button
            style={{ width: "50%" }}
            size="large"
            type="primary"
            htmlType="submit"
          >
            {isLoginMode ? "Login Now" : "Register Now"}
          </Button>
        </Form>
        <ErrorModal error={error} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <p className="auth-register" onClick={switchModeHandler}>
            {isLoginMode
              ? "Don't Have an Account? Register Now"
              : "Have an account? Login Now"}
          </p>
          {isLoginMode && (
            <Link to="/reset-password">
              <p style={{ color: "#808080", textDecoration: "none" }}>
                {isLoginMode ? "Forgot Password?" : null}
              </p>
            </Link>
          )}
        </div>
        {/* <Button inverse onClick={switchModeHandler}>
          Switch to {isLoginMode ? "Signup" : "Login"}
        </Button> */}
      </div>
      <div
        style={{
          width: "50%",
          backgroundColor: "#ECF3FA",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          style={{ width: "70%", padding: "20px", margin: "auto" }}
          src={loginPic}
          alt="login"
        />
      </div>
    </div>
  );
};

export default Auth;

// {
//   /* <Input
//   id="email"
//   element="input"
//   type="email"
//   label="Email"
//   validators={[VALIDATOR_EMAIL()]}
//   errorText="Please enter a valid email address"
//   onInput={inputHandler}
// /> */
// }
