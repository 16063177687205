import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import avatar from "../../../img/avatar.png";
import email from "../../../img/email.png";
import axios from "axios";

import { Layout, Space, Table, Spin } from "antd";

function ProfileSetting() {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [profile, setProfile] = useState();
  const [numPages, setNumPages] = useState(null);
  const [trialDaysLeft, setTrialDaysLeft] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}profile/${auth.userId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        setLoading(false);
        setProfile(response.data);

        // Get subscription information
        const subscriptionResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}subscription/${response.data.stripeSubscriptionId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        const { status, current_period_end } = subscriptionResponse.data;

        if (status === "trialing") {
          // Calculate the remaining trial days
          const today = new Date();
          const trialEnds = new Date(current_period_end * 1000);
          const daysLeft = Math.ceil(
            (trialEnds - today) / (1000 * 60 * 60 * 24)
          );
          setTrialDaysLeft(daysLeft);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, [auth.token, auth.userId]);

  console.log("bills", profile, trialDaysLeft);

  return (
    <div
      style={{
        background: "white",
      }}
    >
      <div className="">
        <div style={{ marginTop: "20px" }}>
          {loading ? (
            <Spin />
          ) : (
            <>
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ width: "8%", marginRight: "20px" }}
                    src={avatar}
                    alt="avatar"
                  />
                  <div>
                    <h2 style={{ margin: 0 }}>{profile?.name}</h2>
                    <p style={{ margin: 0 }}>{profile?.email}</p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6rem",
                  }}
                >
                  {trialDaysLeft !== null && (
                    <div>
                      <p>
                        You have {trialDaysLeft} days left in your trial period.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfileSetting;
